import React, {useState } from "react";
import closeIcon from "../../Assets/close.svg";
import addIcon from "../../Assets/add.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  ADD_HOSPITAL_TITLE,
  ADDRESS_LINE1_ERROR,
  alphaPatternRegex,
  AREA_ERROR,
  BRANCH_NAME_ERROR,
  CANVAS_BOTTOM_STYLE,
  CITY_ERROR,
  CONTACT_ERROR,
  countNonEmptyValues,
  COUNTRY_ERROR,
  floorRegex,
  HIGHEST_LEVELS_ERROR,
  HOSPITAL_CREATE_SUCCESS_MSG,
  HOSPITAL_NAME_ERROR,
  isSearchRegex,
  LANGUAGE_ERROR,
  MAX_CHAR_LENGTH_10,
  MAX_CHAR_LENGTH_100,
  MAX_CHAR_LENGTH_20,
  MAX_CHAR_LENGTH_5,
  MAX_CHAR_LENGTH_50,
  MAX_LENGTH,
  MAXSHIFT_ERROR,
  MINSHIFT_ERROR,
  mobileRegex,
  phoneNumberRegex,
  PINCODE_ERROR,
  SHIFT_ERROR,
  STATE_ERROR,
  SUCCESS,
  TIMEZONE_ERROR,
  validateFields,
} from "../../Utils";
import CancelModal from "../../ReUsable-Components/Cancel-Modal";
import { useDispatch } from "react-redux";
import SuccessSnack from "../../ReUsable-Components/Success-Snack";
import { hospitalPostAction } from "../../Actions/HospitalAction";
export const AddEditHospital = (props) => {
  const propsComponentId = props?.id;
  const initialValues = {
    hospitalName: "",
    shiftNameConvention: "",
    branchName: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    contact: "",
    minShiftTime: "",
    maxShiftTime: "",
    highestLevels: "",
    timezone: "",
    language: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [fieldsData, setFieldsData] = useState([{ key: 0 }]);

  const dispatch = useDispatch();

  const handleChange = (regex) => (e) => {
    const { name, value } = e.target;

    if (isSearchRegex(value, regex)) {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  
  const errorsMapping = {
    hospitalName: HOSPITAL_NAME_ERROR,
    shiftNameConvention: SHIFT_ERROR,
    branchName: BRANCH_NAME_ERROR,
    addressLine1: ADDRESS_LINE1_ERROR,
    area: AREA_ERROR,
    city: CITY_ERROR,
    state: STATE_ERROR,
    country: COUNTRY_ERROR,
    pincode: PINCODE_ERROR,
    contact: CONTACT_ERROR,
    minShiftTime: MINSHIFT_ERROR,
    maxShiftTime: MAXSHIFT_ERROR,
    highestLevels: HIGHEST_LEVELS_ERROR,
    timezone: TIMEZONE_ERROR,
    language: LANGUAGE_ERROR
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const optionalFields = ["addressLine2"];
    const errors = validateFields(formValues, errorsMapping ,optionalFields);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      let body;
      body = finalObject;
      dispatch(hospitalPostAction(body)).then((response) => {
        if (response?.data?.status === SUCCESS) {
          setSuccessMsg(HOSPITAL_CREATE_SUCCESS_MSG)
          setIsSnackOpen(true);
          props?.handleHospitalListUpdate();
        }
      });
    }
  };
  
  const  resetFn = () => {
    setFormValues(initialValues);
    setFormErrors({});
    setFieldsData([initialValues])
  };

  const handleCancelShowModal = () => {
    const enteredFieldsLength = countNonEmptyValues(formValues);
    if (enteredFieldsLength > 0) {
      setShowCancelModal(true);
    } else {
      cancelFn();
    }
  };

  const handleCancelCloseModal = () => {
    setShowCancelModal(false);
  };

  const cancelFn = () => {
    document.getElementById(propsComponentId)?.click();
    resetFn();
    handleCancelCloseModal();
  };

  const handleSnackClose = () => {
    setIsSnackOpen(false);
    resetFn();
    document.getElementById(propsComponentId)?.click();
  };

  const handleRemoveField = (index) => {
    fieldsData.splice(index, 1);
    setFieldsData([...fieldsData]);
  };
  
  const handleFieldsChange = (value, fieldName, index,regex) => {
    
    if(isSearchRegex(value,regex)){
      
    let tempData = [...fieldsData];
    tempData[index][fieldName] = value;
    setFieldsData(tempData);
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    }
    setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));    
  };

      const finalObject = {
        hospitalName: formValues?.hospitalName,
        shiftNameConvention: formValues?.shiftNameConvention,
        branches: fieldsData.map((branch) => ({
          branchName: branch.branchName,
          minShiftDuration: branch.minShiftTime,
          maxShiftDuration: branch.maxShiftTime,
          timezone: branch.timezone,
          maxLevelsOfGrades: branch.highestLevels,
          language: branch.language,
          address: {
            addressLine1: branch.addressLine1,
            addressLine2: branch.addressLine2,
            area: branch.area,
            zipCode: branch.pincode,
            city: branch.city,
            state: branch.state,
            country: branch.country,
            contactNumber: branch.contact,
          },
        })),
      };
  
  return (
    <>
      <div className="backgroundDiv h-100">
        <SuccessSnack
          open={isSnackOpen}
          onClose={handleSnackClose}
          successMessage={successMsg}
        />
        <span data-bs-dismiss="offcanvas" id={propsComponentId}></span>
        <div className="modalHeader border border-end-0 border-top-0 border-bottom-1 border-start-0 align-items-center d-flex justify-content-between p-3">
          <span className="titleHeader">{ADD_HOSPITAL_TITLE}</span>
          <span
            className="me-0 mt-1 close_button"
            aria-label="Close"
            onClick={handleCancelShowModal}
          >
            <img src={closeIcon} alt="img" />
          </span>
        </div>
        <form className="needs-validation" onSubmit={handleSubmit} noValidate>
          <div className="sideTrigger">
            <div className="offcanvas-body ms-2 pt-0 m-0">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="firstName" className="mt-3 labels">
                      Hospital Name <span className="text text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control  ${
                        formErrors?.hospitalName ? "errorfocus" : "inputfocus"
                      }`}
                      name="hospitalName"
                      placeholder="Enter Hospital Name"
                      value={formValues?.hospitalName}
                      onChange={handleChange(floorRegex)}
                      maxLength={MAX_LENGTH}
                    ></input>
                    <span className="text error-style">
                      {formErrors.hospitalName ? formErrors.hospitalName : null}
                    </span>
                  </div>
                </div>
                
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="grade" className="mt-3 labels">
                      Shift Naming Convention
                      <span className="text text-danger">*</span>
                    </label>
                    <select
                      className={`form-select thin-scrollbar ${
                        formErrors?.shiftNameConvention
                          ? "errorfocus"
                          : "inputfocus"
                      }`}
                      name="shiftNameConvention"
                      value={formValues?.shiftNameConvention}
                      onChange={handleChange()}
                    >
                      <option value="">Select</option>
                      <option value="ALPHANUMERIC">ALPHANUMERIC</option>
                      <option value="NUMERIC">NUMERIC</option>
                      <option value="ALPHABETS">ALPHABETS</option>
                    </select>
                  </div>
                  {formErrors?.shiftNameConvention ? (
                    <span className="text error-style">{formErrors?.shiftNameConvention}</span>
                  ) : null}
                </div>
              </div>
              <div className="border border-end-0 border-top-0 border-bottom-1 border-start-0 mt-4"></div>
              {fieldsData?.map((eachField, index) => (
                <div>
                  {index !== 0 && (
                    <div className="border border-end-0 border-top-0 border-bottom-2 border-start-0 border-dark mt-3"></div>
                  )}
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <label className="labels_add_trigger custom-underline">
                      Branch Details - {index + 1}
                    </label>
                    {index === 0 && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>Add</Tooltip>}
                      >
                        <div
                          className="addDiv d-flex align-items-center justify-content-end rounded-2 p-2 ms-auto"
                          onClick={() =>
                            setFieldsData([
                              ...fieldsData,
                              { key: fieldsData.length },
                            ])
                          }
                        >
                          <img src={addIcon} alt="img" className="me-2" />
                          <span>Add</span>
                        </div>
                      </OverlayTrigger>
                    )}
                    {index !== 0 && (
                      <div className="d-flex align-items-center justify-content-end rounded-2 p-2 close-cursor">
                        <img
                          src={closeIcon}
                          alt="Remove"
                          onClick={() => handleRemoveField(index)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="mt-1 labels">
                          Branch Name
                          <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.branchName ? "errorfocus" : "inputfocus"
                          }`}
                          name="branchName"
                          placeholder="Enter Branch Name"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "branchName",
                              index,
                              floorRegex
                            )
                          }
                          value={eachField?.branchName || ""}
                          maxLength={MAX_LENGTH}
                        ></input>
                      </div>
                      <span className="text error-style">
                        {formErrors.branchName ? formErrors.branchName : null}
                      </span>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="mt-1 labels">
                          Address Line 1
                          <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.addressLine1
                              ? "errorfocus"
                              : "inputfocus"
                          }`}
                          name="addressLine1"
                          placeholder="Enter Address Line 1"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "addressLine1",
                              index
                            )
                          }
                          value={eachField?.addressLine1 || ""}
                          maxLength={MAX_CHAR_LENGTH_100}
                        ></input>
                      </div>
                      <span className="text error-style">
                        {formErrors.addressLine1
                          ? formErrors.addressLine1
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="mt-2 labels">
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.addressLine2
                              ? "errorfocus"
                              : "inputfocus"
                          }`}
                          name="addressLine2"
                          placeholder="Enter Address Line 2"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "addressLine2",
                              index
                            )
                          }
                          value={eachField?.addressLine2 || ""}
                          maxLength={MAX_CHAR_LENGTH_100}
                        ></input>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="mt-2 labels">
                          Area <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.area ? "errorfocus" : "inputfocus"
                          }`}
                          name="area"
                          placeholder="Enter Area"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "area",
                              index,
                              floorRegex
                            )
                          }
                          value={eachField?.area || ""}
                          maxLength={MAX_LENGTH}
                        ></input>
                      </div>
                      
                      <span className="text error-style">
                        {formErrors?.area ? formErrors.area : null}
                      </span>
                      
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="labels">
                          City<span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.city ? "errorfocus" : "inputfocus"
                          }`}
                          name="city"
                          placeholder="Enter City"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "city",
                              index,
                              alphaPatternRegex
                            )
                          }
                          value={eachField?.city || ""}
                          
                          maxLength={MAX_LENGTH}
                        ></input>
                      </div>
                      
                      <span className="text error-style">
                        {formErrors?.city ? formErrors.city : null}
                      </span>
                      
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="labels">
                          State <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.state ? "errorfocus" : "inputfocus"
                          }`}
                          name="state"
                          placeholder="Enter State"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "state",
                              index,
                              alphaPatternRegex
                            )
                          }
                          value={eachField?.state || ""}
                          maxLength={MAX_LENGTH}
                        ></input>
                      </div>
                      
                      <span className="text error-style">
                        {formErrors?.state ? formErrors.state : null}
                      </span>
                      
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className=" labels">
                          Country <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.country ? "errorfocus" : "inputfocus"
                          }`}
                          name="country"
                          placeholder="Enter Country"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "country",
                              index,
                              alphaPatternRegex
                            )
                          }
                          value={eachField?.country || ""}
                          maxLength={MAX_CHAR_LENGTH_5}
                        ></input>
                      </div>
                      
                      <span className="text error-style">
                        {formErrors?.country ? formErrors.country : null}
                      </span>
                      
                    </div>

                    <div className="col-6 ">
                      <div className="form-group">
                        <label htmlFor="firstName" className=" labels">
                          Pincode <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.pincode ? "errorfocus" : "inputfocus"
                          }`}
                          name="pincode"
                          placeholder="Enter Pincode"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "pincode",
                              index,
                              floorRegex
                            )
                          }
                          value={eachField?.pincode || ""}
                          maxLength={MAX_CHAR_LENGTH_10}
                        ></input>
                      </div>
                      
                      <span className="text error-style">
                        {formErrors?.pincode ? formErrors.pincode : null}
                      </span>
                      
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="labels">
                          Contact <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.contact ? "errorfocus" : "inputfocus"
                          }`}
                          name="contact"
                          placeholder="Enter Contact"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "contact",
                              index,
                              phoneNumberRegex
                            )
                          }
                          value={eachField?.contact || ""}
                          maxLength={MAX_CHAR_LENGTH_50}
                        ></input>
                      </div>
                      <span className="text error-style">
                        {formErrors?.contact ? formErrors.contact : null}
                      </span>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="labels">
                          Min Shift Time (Hrs)
                          <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.minShiftTime
                              ? "errorfocus"
                              : "inputfocus"
                          }`}
                          name="minShiftTime"
                          placeholder="Enter Min. Shift Time"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "minShiftTime",
                              index,
                              mobileRegex
                            )
                          }
                          value={eachField?.minShiftTime || ""}
                        ></input>
                      </div>
                      
                      <span className="text error-style">
                        {formErrors?.minShiftTime
                          ? formErrors.minShiftTime
                          : null}
                      </span>
                      
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className=" labels">
                          Max Shift Time (Hrs)
                          <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.maxShiftTime
                              ? "errorfocus"
                              : "inputfocus"
                          }`}
                          name="maxShiftTime"
                          placeholder="Enter Max Shift Time"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "maxShiftTime",
                              index,
                              mobileRegex
                            )
                          }
                          value={eachField?.maxShiftTime || ""}
                        ></input>
                      </div>
                      <span className="text error-style">
                        {formErrors?.maxShiftTime
                          ? formErrors.maxShiftTime
                          : null}
                      </span>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className="labels">
                          No. Of Highest Levels
                          <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.highestLevels
                              ? "errorfocus"
                              : "inputfocus"
                          }`}
                          name="highestLevels"
                          placeholder="Enter Highest Levels"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "highestLevels",
                              index,
                              mobileRegex
                            )
                          }
                          value={eachField?.highestLevels || ""}
                        ></input>
                      </div>
                      <span className="text error-style">
                        {formErrors?.highestLevels
                          ? formErrors.highestLevels
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className=" labels">
                          Timezone <span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.timezone ? "errorfocus" : "inputfocus"
                          }`}
                          name="timezone"
                          placeholder="Enter Timezone"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "timezone",
                              index,
                              alphaPatternRegex
                            )
                          }
                          value={eachField?.timezone || ""}
                          maxLength={MAX_CHAR_LENGTH_50}
                        ></input>
                      </div>
                      
                      <span className="text error-style">
                        {formErrors?.timezone ? formErrors.timezone : null}
                      </span>
                      
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="firstName" className=" labels">
                          Language<span className="text text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control mt-1 ${
                            formErrors?.language ? "errorfocus" : "inputfocus"
                          }`}
                          name="language"
                          placeholder="Enter Language"
                          onChange={(e) =>
                            handleFieldsChange(
                              e.target.value,
                              "language",
                              index,
                              alphaPatternRegex
                            )
                          }
                          value={eachField?.language || ""}
                          maxLength={MAX_CHAR_LENGTH_20}
                        ></input>
                      </div>
                      
                      <span className="text error-style">
                        {formErrors?.language ? formErrors.language : null}
                      </span>
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="modalHeader w-100 border border-end-0 border-top-1 border-bottom-0 border-start-0 align-items-center d-flex justify-content-between p-4"
            style={CANVAS_BOTTOM_STYLE}
          >
            <div
              className="reset-button align-items-center d-flex justify-content-center rounded-2"
              onClick={resetFn}
            >
              <span className="reset-text">Reset</span>
            </div>
            <div className="d-flex">
              <div
                className="reset-button align-items-center d-flex px-3 justify-content-center rounded-2"
                onClick={handleCancelShowModal}
              >
                <span className="reset-text">Cancel</span>
              </div>
              <button
                type="submit"
                className="save-button align-items-center ms-3 d-flex border justify-content-center rounded-2"
              >
                <span className="saveText">Save</span>
              </button>
            </div>
          </div>
        </form>
        <CancelModal
          show={showCancelModal}
          handleClose={handleCancelCloseModal}
          resetCancel={cancelFn}
        />
      </div>
    </>
  );
};

