export const GRADESFETCH_SUCCESS="GRADESFETCH_SUCCESS";
export const GRADESFETCH_FAIL="GRADESFETCH_FAIL";

export const GRADESPOST_SUCCESS="GRADESPOST_SUCCESS";
export const GRADESPOST_FAIL="GRADESPOST_FAIL";

export const GRADESEDIT_SUCCESS="GRADESEDIT_SUCCESS";
export const GRADESEDIT_FAIL="GRADESEDIT_FAIL";

export const GRADESDELETE_SUCCESS="GRADESDELETE_SUCCESS";
export const GRADESDELETE_FAIL="GRADESDELETE_FAIL";

export const OPEN_MODAL = 'OPEN_MODAL';
export const OPEN_CANVAS='OPEN_CANVAS';