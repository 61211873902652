export const REQUEST_STAFFLIST_SUCCESS = ' REQUEST_STAFFLIST_SUCCESS';
export const REQUEST_STAFFLIST_FAIL = ' REQUEST_STAFFLIST_FAIL';
export const CREATE_STAFFWORKER_SUCCESS = ' REQUEST_STAFFLIST_SUCCESS';
export const CREATE_STAFFWORKER_FAILURE = ' REQUEST_STAFFLIST_FAILURE';
export const ROLES_LIST_SUCCESS = 'ROLES_LIST_SUCCESS';
export const ROLES_LIST_FAILURE='ROLES_LIST_FAILURE';
export const WORKER_CREATED_SUCCESFULLY ='WORKER CREATED SUCCESSFULLY';
export const OPEN_MODAL = 'OPEN_MODAL';
export const OPEN_CANVAS='OPEN_CANVAS';
export const RESET_FORM = 'RESET_FORM';
export const EDIT_STAFFWORKER_SUCCESS='EDIT_STAFFWORKER_SUCCESS';
export const EDIT_STAFFWORKER_FAILURE='EDIT_STAFFWORKER_FAILURE';
export const TEMPLATE_DOWNLOAD_SUCCESS='TEMPLATE_DOWNLOAD_SUCCESS';
export const TEMPLATE_DOWNLOAD_FAILURE='TEMPLATE_DOWNLOAD_FAILURE';
export const TEMPLATE_UPLOAD_SUCCESS='TEMPLATE_UPLOAD_SUCCESS';
export const TEMPLATE_UPLOAD_FAILURE='TEMPLATE_UPLOAD_FAILURE';



