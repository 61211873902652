import Accordion from "react-bootstrap/Accordion";
import { HOSPITAL_TITLE } from "../Utils";
const DetailsAccordion = ({ eventKey,title,hospitalDetails}) => {
    return (
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>
        {(title===HOSPITAL_TITLE)?
          <table className="table">
            <tbody>
              <tr>
                <td className="table-td border">
                  <div className="table-label ">Hospital Name</div>
                  <div className="card-label ">
                    {hospitalDetails?.hospitalName}
                  </div>
                </td>
                <td className="table-td border">
                  <div className="table-label ">Hospital Code</div>
                  <div className="card-label ">
                    {hospitalDetails?.hospitalCode}
                  </div>
                </td>
                <td className="table-td border">
                  <div className="table-label ">Language</div>
                  <div className="card-label ">{hospitalDetails?.branches[0]?.language}</div>
                </td>
              </tr>
              <tr>
                <td className="table-td border">
                  <div className="table-label ">Branch Name</div>
                  <div className="card-label ">
                    {hospitalDetails?.branches[0]?.branchName}
                  </div>
                </td>
                <td className="table-td border">
                  <div className="table-label ">Branch Code</div>
                  <div className="card-label ">
                    {hospitalDetails?.branches[0]?.branchCode}
                  </div>
                </td>
                <td className="table-td border">
                  <div className="table-label ">Min Shift Time</div>
                  <div className="card-label ">
                    {hospitalDetails?.branches[0]?.minShiftDuration}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="table-td border">
                  <div className="table-label ">Max Shift Time</div>
                  <div className="card-label ">
                    {hospitalDetails?.branches[0]?.maxShiftDuration}
                  </div>
                </td>
                <td className="table-td border">
                  <div className="table-label ">No of Highest Levels</div>
                  <div className="card-label ">
                    {hospitalDetails?.branches[0]?.maxLevelsOfGrades}
                  </div>
                </td>
                <td className="table-td border">
                  <div className="table-label ">Timezone</div>
                  <div className="card-label ">{hospitalDetails?.branches[0]?.timezone}</div>
                </td>
              </tr>
              <tr>
              </tr>
              <tr>
                <td className="table-td border" colSpan={3}>
                  <div className="table-label ">Address</div>
                  <div className="card-label ">
                    {hospitalDetails?.branches[0]?.address?.addressLine1},
                    {hospitalDetails?.branches[0]?.address?.addressLine2},
                    {hospitalDetails?.branches[0]?.address?.area},
                    {hospitalDetails?.branches[0]?.address?.city},
                    {hospitalDetails?.branches[0]?.address?.state},
                    {hospitalDetails?.branches[0]?.address?.zipCode},
                    {hospitalDetails?.branches[0]?.address?.contactNumber}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>:''}
        </Accordion.Body>
      </Accordion.Item>
    );
  };
  
  export default DetailsAccordion;
  