export class AddStaff{
    firstName;
    lastName;
    emailId;
    phoneNo;
    hospitalId;
    otp;
    otpGenerationTime;
    isActive;
    managerId;
    roleId;
    department;
    gradeId;
    createdBy;

}
export class Roles{
    roleId;
}
export class Department{
    departmentId;
}
export class Grades{
   gradeId;
}