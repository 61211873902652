import {Api} from '../Interceptor/Interceptor';
import { BRANCH_DETAILS_API_END_POINT, HOSPITAL_API_END_POINT, HOSPITAL_LISTS_API_END_POINT } from './hospitalApiEndPoints';

export const fetchHospitalsService=()=>{
    return Api.get(HOSPITAL_LISTS_API_END_POINT);
}

export const  fetchHospitalDetailsService=(hospitalId,branchId)=>{
    return Api.get(BRANCH_DETAILS_API_END_POINT, {
      params: {
        hospitalIdParam: hospitalId,
        branchId: branchId,
      },
    });
}

export const postHospitalService = (body)=>{
  return Api.post(HOSPITAL_API_END_POINT, body)
}

export const HospitalService={
    fetchHospitalsService,
    fetchHospitalDetailsService,
    postHospitalService
}