import { HOSPITALS_FETCH_SUCCESS,HOSPITALS_FETCH_FAIL,HOSPITAL_DETAILS_FETCH_SUCCESS,HOSPITAL_DETAILS_FETCH_FAIL, HOSPITAL_CREATE_SUCCESS, HOSPITAL_CREATE_FAILURE } from '../ActionTypes/HospitalActionTypes';
import { SET_MESSAGE } from '../ActionTypes/GenericTypes';
import { getErrorMessage } from '../Utils';
import { HospitalService } from '../Services/HospitalService';


/**
 * This method fetches hospital lists using a Redux action which fetches data from an API
 * @returns list of hospitals
 */
export const hospitalsFetchAction = ()=>(dispatch) =>{
    return HospitalService.fetchHospitalsService().then(
        (response) => {
            hospitalsFetchDispatcher(response,dispatch);
            return Promise.resolve(response);
          },
          (error) => {
            const message = getErrorMessage(error);
            hospitalsFetchError(message,dispatch);
          }
    )
}

/**
 * This method fetches hospital details using a Redux action which fetches data from an API
 * @returns details of the hospital
 */


export const hospitalDetailsFetchAction = (hospitalId,branchId)=>(dispatch) =>{
  
  return HospitalService.fetchHospitalDetailsService(hospitalId,branchId).then(
      (response) => {
          hospitalDetailsFetchDispatcher(response,dispatch);
          return Promise.resolve(response);
        },
        (error) => {
          const message = getErrorMessage(error);
          hospitalDetailsFetchError(message,dispatch);
        }
  )
}

export const hospitalPostAction = (body) => (dispatch) => {
  return HospitalService.postHospitalService(body).then(
    (response) => {
      hospitalPostDispatcher(response, dispatch);

      return Promise.resolve(response);
    },
    (error) => {
      const message = getErrorMessage(error);

      hospitalPostError(message, dispatch);
    }
  );
};

const hospitalPostDispatcher = (response,dispatch) =>{
  dispatch({
    type: HOSPITAL_CREATE_SUCCESS,
    payload: response,
  }); 
}

const hospitalPostError = (message,dispatch)=>{
  dispatch({
    type: HOSPITAL_CREATE_FAILURE,
  });

  dispatch({
    type: SET_MESSAGE,
    payload: message,
  });
}

const hospitalsFetchDispatcher = (response,dispatch) =>{
    dispatch({
      type: HOSPITALS_FETCH_SUCCESS,
      payload: response,
    }); 
  }

  const hospitalsFetchError=(message,dispatch)=>{
    dispatch({
      type: HOSPITALS_FETCH_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });
}

const hospitalDetailsFetchDispatcher=(response,dispatch)=>{
  dispatch({
    type: HOSPITAL_DETAILS_FETCH_SUCCESS,
    payload: response,
  }); 
}

const hospitalDetailsFetchError=(message,dispatch)=>{
  dispatch({
    type: HOSPITAL_DETAILS_FETCH_FAIL,
  });

  dispatch({
    type: SET_MESSAGE,
    payload: message,
  });
}