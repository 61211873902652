export const TIMESLOTS_GETALL_SUCCESS = "TIMESLOTS_GETALL_SUCCESS";
export const TIMESLOTS_CREATE_SUCCESS = "TIMESLOTS_CREATE_SUCCESS";
export const TIMESLOTS_UPDATE_SUCCESS = "TIMESLOTS_UPDATE_SUCCESS";
export const TIMESLOTS_DELETE_SUCCESS = "TIMESLOTS_DELETE_SUCCESS";

export const FlOOR_GETALL_SUCCESS = "FlOOR_GETALL_SUCCESS";
export const FlOOR_CREATE_SUCCESS = "FlOOR_CREATE_SUCCESS";
export const FlOOR_UPDATE_SUCCESS = "FlOOR_UPDATE_SUCCESS";
export const FlOOR_DELETE_SUCCESS = "FlOOR_DELETE_SUCCESS";

export const STATION_GETALL_SUCCESS = "STATION_GETALL_SUCCESS";
export const STATION_CREATE_SUCCESS = "STATION_CREATE_SUCCESS";
export const STATION_UPDATE_SUCCESS = "STATION_UPDATE_SUCCESS";
export const STATION_DELETE_SUCCESS = "STATION_DELETE_SUCCESS";