import React, { useCallback, useState } from "react";
import BootstrapSidebar from "../Sidebar/BootstrapSidebar";
import Header from "../Header/Header";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import addIcon from "../../Assets/add.svg";
import editIcon from "../../Assets/edit.svg";
import searchIcon from "../../Assets/blueSearchIcon.svg";
import exportIcon from "../../Assets/exportIcon.svg";
import Footer from "../Footer/Footer";
import hospitalBackground from "../../Assets/HospitalBackground.svg";
import DetailsAccordion from "../../ReUsable-Components/Details-Accordian";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  API_SUCCESS_STATUS_CODE,
  FULL_NAME_LENGTH,
  HOSPITAL_CANVAS_ID,
  HOSPITAL_COMPONENT_ID,
  HOSPITAL_TITLE,
  MAX_HOSPITAL_NAME_LENGTH,
  modalWidth,
  PROOCESS_TITLE,
  ROASTER_TITLE,
  STAFF_TITLE,
  ZERO,
} from "../../Utils";
import { hospitalDetailsFetchAction, hospitalsFetchAction } from "../../Actions/HospitalAction";
import { useEffect } from "react";
import ReusableModal from "../../ReUsable-Components/Off-Canvas";
import { AddEditHospital } from "./Add-Edit-Hospital";

export const HospitalLists = () => {
  const [showHospitalDetails, setShowHospitalDetails] = useState(true);

  const handleCardClick = (val) => {
    toggleHospitalDetailsView();
    fetchBranchDetails(val?.hospitalId,val?.branchId);
  };

  const toggleHospitalDetailsView = () => {
    setShowHospitalDetails(false);
  };

  const [hospitalLists,sethospitalLists]=useState([]);
  const [hospitalDetails,sethospitalDetails]=useState();

  const dispatch = useDispatch();

  const hospitalListFn = useCallback(() => {
    /**
     *  Fetches hospital lists using Redux and update state based on the response or error.
     */
    dispatch(hospitalsFetchAction()).then(
      (response) => {
        if (response?.status === API_SUCCESS_STATUS_CODE) {
          sethospitalLists(response?.data?.body);
        }
      },
    );

  }, [dispatch]);
  useEffect(() => {
    hospitalListFn()
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  


  const fetchBranchDetails=(hospitalId,branchId)=>{
      /**
     * Fetches hospital details using Redux and update state based on the response or error.
     */
      dispatch(hospitalDetailsFetchAction(hospitalId,branchId)).then(
        (response) => {
          if (response?.status === API_SUCCESS_STATUS_CODE) {
            sethospitalDetails(response?.data?.body);
          }
        },
      );
    }  

  return (
    <div className="d-flex">
      <div>
        <BootstrapSidebar />
      </div>
      <div className="tableBackground ms-5">
        <div>
          <Header />
        </div>
        <div>
          <div className="headerOne border d-flex justify-content-between align-items-center p-4">
            <span className="titleHeader">Hospitals</span>
            <div className="d-flex align-items-center">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Add</Tooltip>}
              >
                <div
                  className="addDiv p-2 d-flex align-items-center rounded-2"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#addHospital"
                  aria-controls="offcanvasRight"
                >
                  <img src={addIcon} className="me-2" alt="img" />
                  <span>Add</span>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Edit</Tooltip>}
              >
                <div className="ms-2 addDiv p-2 d-flex align-items-center rounded-2">
                  <img src={editIcon} className="me-2" alt="img" />
                  <span>Edit</span>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Export</Tooltip>}
              >
                <div className="ms-2 addDiv p-2 d-flex align-items-center rounded-2">
                  <img src={exportIcon} className="me-2" alt="img" />
                  <span>Export</span>
                </div>
              </OverlayTrigger>
            </div>
          </div>
          <div className="ms-4">
            <div className="d-flex">
              <div className="m-1 p-3 h-100 hospitals-div-style close-cursor">
                <div className=" search align-items-center d-flex justify-content-center rounded-0">
                  <img src={searchIcon} className="ms-2" alt="img" />
                  <input
                    type="text"
                    placeholder="Search..."
                    className="search_input w-100 ms-2"
                  />
                </div>
                <div className="row mt-3 p-1">
                  <div className="col-3 filter-labels">
                    <span className="d-flex justify-content-between">
                      Hospital Name
                      {/**
                       * Kept this commented code for future use
                       */}
                      {/* <img
                        src={filterIcon}
                        alt=""
                        className="border border-top-0 border-bottom-0 border-start-0 pe-2"
                      ></img> */}
                    </span>
                  </div>
                  <div className="col-3 filter-labels ps-0">
                    <span className="d-flex justify-content-between">
                      Hospital Code
                    </span>
                  </div>
                  <div className="col-3 filter-labels ps-0">
                    <span className="d-flex justify-content-between">
                      Branch Name
                    </span>
                  </div>
                  <div className="col-3 filter-labels ps-0">
                    <span className="d-flex justify-content-between ">
                      Branch Code
                    </span>
                  </div>
                </div>
                <div className="hospital-scroll-container">
                  {hospitalLists?.map((val, index) => (
                    <div
                      key={index}
                      className="border rounded-2 p-2 card-border mt-2"
                      onClick={() => handleCardClick(val)}
                    >
                      <div>
                        <div className="row">
                          <div className="col-3 card-label">
                            {val?.hospitalName?.length > FULL_NAME_LENGTH ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip>{val.hospitalName}</Tooltip>}
                              >
                                <span>
                                  {`${val.hospitalName.substring(
                                    ZERO,
                                    MAX_HOSPITAL_NAME_LENGTH
                                  )}...`}
                                </span>
                              </OverlayTrigger>
                            ) : (
                              val.hospitalName
                            )}
                          </div>
                          <div className="col-3 card-content">
                            {val?.hospitalCode}
                          </div>
                          <div className="col-3 card-content">
                            {val?.branchName}
                          </div>
                          <div className="col-3 card-content">
                            {val?.branchCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="ms-2 accordian-div-style">
                {showHospitalDetails ? (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="text-align-center">
                      <p>Choose a hospital from the list to view details</p>
                      <div className="d-flex justify-content-center">
                        <img src={hospitalBackground} alt="img" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Accordion defaultActiveKey="0">
                    <DetailsAccordion eventKey={0} 
                    title={HOSPITAL_TITLE} hospitalDetails={hospitalDetails}/>
                    <DetailsAccordion eventKey={1} 
                    title={STAFF_TITLE}/>
                    <DetailsAccordion eventKey={2} 
                    title={PROOCESS_TITLE}/>
                    <DetailsAccordion eventKey={3} 
                    title={ROASTER_TITLE}/>
                  </Accordion>
                )}
              </div>
            </div>
          </div>
          <ReusableModal
          id={HOSPITAL_CANVAS_ID}
          style={modalWidth}
          >
            <AddEditHospital id={HOSPITAL_COMPONENT_ID} handleHospitalListUpdate={hospitalListFn}></AddEditHospital>
          </ReusableModal>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
