export const ROLESFETCH_SUCCESS="ROLESFETCH_SUCCESS";
export const ROLESFETCH_FAIL="ROLESFETCH_FAIL";

export const ROLESPOST_SUCCESS="ROLESPOST_SUCCESS";
export const ROLESPOST_FAIL="ROLESPOST_FAIL";

export const ROLESEDIT_SUCCESS="ROLESEDIT_SUCCESS";
export const ROLESEDIT_FAIL="ROLESEDIT_FAIL";

export const ROLESDELETE_SUCCESS="ROLESEDIT_SUCCESS";
export const ROLESDELETE_FAIL="ROLESDELETE_FAIL";




