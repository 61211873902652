export const GET_ALL_PROCESS_DATA_SUCCESS = "GET_ALL_PROCESS_DATA_SUCCESS";
export const GET_ALL_PROCESS_DATA_FAILURE = "GET_ALL_PROCESS_DATA_FAILURE";
export const ADD_PROCESS_FLOW_SUCCESS = "ADD_PROCESS_FLOW_SUCCESS";
export const ADD_PROCESS_FLOW_FAILURE = "ADD_PROCESS_FLOW_FAILURE";
export const GET_PROCESS_FROM_ID_FLOW_SUCCESS = "GET_PROCESS_FROM_ID_FLOW_SUCCESS";
export const GET_PROCESS_FROM_ID_FLOW_FAILURE = "GET_PROCESS_FROM_ID_FLOW_FAILURE";
export const SET_PROCESS_ID_DATA_EMPTY = "SET_PROCESS_ID_DATA_EMPTY";
export const SET_PROCESS_TASKS_DATA_EMPTY = "SET_PROCESS_TASKS_DATA_EMPTY";
export const GET_PROCESS_TASKS_FLOW_SUCCESS = "GET_PROCESS_TASKS_FLOW_SUCCESS";
export const GET_PROCESS_TASKS_FLOW_FAILURE = "GET_PROCESS_TASKS_FLOW_FAILURE";
export const PROCESS_FLOW_GET_TASK_SUCCESS = "PROCESS_FLOW_GET_TASK_SUCCESS";
export const PROCESS_FLOW_GET_TASK_FAILURE = "PROCESS_FLOW_GET_TASK_FAILURE";
export const PROCESS_FLOW_ADD_TASK_SUCCESS = "PROCESS_FLOW_ADD_TASK_SUCCESS";
export const PROCESS_FLOW_ADD_TASK_FAILURE = "PROCESS_FLOW_ADD_TASK_FAILURE";
export const PROCESS_FLOW_UPDATE_TASK_SUCCESS = "PROCESS_FLOW_UPDATE_TASK_SUCCESS";
export const PROCESS_FLOW_UPDATE_TASK_FAILURE = "PROCESS_FLOW_UPDATE_TASK_FAILURE";
export const PROCESS_FLOW_DELETE_TASK_SUCCESS = "PROCESS_FLOW_DELETE_TASK_SUCCESS";
export const PROCESS_FLOW_DELETE_TASK_FAILURE = "PROCESS_FLOW_DELETE_TASK_FAILURE";
export const ADD_PROCESS_DATA = 'ADD_PROCESS_DATA';
export const PROCESS_FLOW_SUCCESS_START = 'PROCESS_FLOW_SUCCESS_START';
export const PROCESS_FLOW_SUCCESS_STOP = 'PROCESS_FLOW_SUCCESS_STOP';
export const PROCESS_FLOW_DELETE_PROCESS_SUCCESS = 'PROCESS_FLOW_DELETE_PROCESS_SUCCESS';
export const PROCESS_FLOW_DELETE_PROCESS_FAILURE = 'PROCESS_FLOW_DELETE_PROCESS_FAILURE';